const global = (typeof window !== "undefined") ? window : (0, eval)("this");

import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
const analytics = Analytics({
  app: 'chainglance',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-QJKW5JWRX8']
    })
  ]
});
analytics.page();

import jquery from 'jquery';
global.$ = window.$ = global.jQuery = window.jQuery = jquery;

import * as Cookies from "js-cookie";
global.Cookies = window.Cookies = Cookies;

import * as bootstrap from "bootstrap"
global.bootstrap = window.bootstrap = bootstrap;
import "./assets/scss/bootstrap.scss"

import Swiper from "swiper/swiper-bundle.mjs";
global.Swiper = window.Swiper = Swiper;
import "swiper/swiper-bundle.css"

import "./assets/fontawesome/css/all.min.css"

import "./assets/scss/icons.scss"
import "@tarekraafat/autocomplete.js/dist/css/autoComplete.css"
import "./assets/scss/app.scss"
import "./assets/scss/custom.scss"
import "./app.scss"

require("simplebar")
import * as Waves from "node-waves"
global.Waves = window.Waves = Waves;
import * as feather from "feather-icons"
global.feather = window.feather = feather;
import * as autoComplete from "@tarekraafat/autocomplete.js";
global.autoComplete = window.autoComplete = autoComplete;

require("./constants.js");
const topdomain = window._topdomain;

require("./assets/js/pages/plugins/lord-icon-2.1.0.js")

// dark/light is overridden at cookie level before the template code runs
var dataBsTheme = Cookies.get("data-bs-theme");
if (dataBsTheme)
{
  document.documentElement.setAttribute("data-bs-theme", dataBsTheme);
  sessionStorage.setItem("data-bs-theme", dataBsTheme);
}

require("./assets/js/layout.js")
//require("./assets/js/plugins.js")
require("./assets/js/app.js")

require("./assets/js/pages/landing.init.js");

import { BrowserCodeReader, BrowserQRCodeReader } from "@zxing/browser";
import detectEthereumProvider from "@metamask/detect-provider";

function setupAddressSearch()
{
  const addressesStorageKey = "addresses";

  var addresses = JSON.parse(window.Cookies.get(addressesStorageKey) || '[]');
  if (addresses && addresses.length && typeof addresses[0] === "string")
  {
    addresses = addresses.map((item) => { return {value:item}; });
    window.Cookies.set(addressesStorageKey, JSON.stringify(addresses), {domain:"." + topdomain, path:"/", expires:365});
  }

  var $f = $("#blockchainAddressForm");
  $f.on("submit", function (e)
  {
    e.preventDefault();

    var $a = $("#blockchainAddressInput", $f);
    var addr = $a.val().trim();
    if (!addr)
    {
      return false;
    }
    var pattern = $a.attr("pattern")?.value
    if (pattern && !new RegExp(pattern).test(addr))
    {
      return false;
    }

    addr = addr.toLowerCase();

    var p = addresses.find((item) => (typeof item === "string" && item === addr) || (typeof item.value !== "undefined" && item.value === addr));
    if (p)
    {
      addresses.splice(p, 1);
    }
    else
    {
      p = {value:addr};
    }
    addresses.splice(0, 0, p);
    if (addresses.length > 10)
    {
      addresses.splice(10, addresses.length - 10);
    }
    window.Cookies.set(addressesStorageKey, JSON.stringify(addresses), {domain:"." + topdomain, path:"/", expires:365});

    window.location.href = $f.attr("action") + '/' + addr;
  });

  if (addresses.length)
  {
    var autoCompleteFruit = new autoComplete({
      selector: "#blockchainAddressInput",
      data: {
        src: addresses.map(item => item.value),
        cache: false
      },
      threshold: 0,
      resultsList: {
        noResults: false
      },
      resultItem: {
        highlight: true,
        element: function (item, data)
        {
          $(item).append('<i class="fa fa-times" data-addr="' + data.value + '"></i>');
        }
      },
      submit: true,
      events: {
        input: {
          focus: function (e)
          {
            autoCompleteFruit.start();
          },

          selection: function selection(e)
          {
            var selection = e.detail.selection.value;
            autoCompleteFruit.input.value = selection;
            setTimeout(() =>
            {
              if (autoCompleteFruit.input.value)
              {
                $f.trigger("submit");
              }
            }, 100);
          }
        }
      }
    });
    $(".autoComplete_wrapper", $f).on("click", "li i.fa-times", function (e)
    {
      var addr = $(this).attr("data-addr");
      var p = addresses.indexOf(addr);
      if (p >= 0)
      {
        addresses.splice(p, 1);
      }
      window.Cookies.set(addressesStorageKey, JSON.stringify(addresses), {domain:"." + topdomain, path:"/", expires:365});
      if (autoCompleteFruit.input.value === addr)
      {
        autoCompleteFruit.input.value = "";
      }
      document.activeElement.blur();
    });
    autoCompleteFruit.init();
  }
}

async function setupMetamask()
{
  const provider = await detectEthereumProvider();
  if (!provider || typeof window.ethereum === "undefined")
  {
    return;
  }

  $("html").addClass("has-metamask");

  $("#blockchainAddressForm li.has-metamask > a").on("click", async () =>
  {
    var start = new Date().getTime();
    do
    {
      try {
        var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        if (accounts && accounts.length)
        {
          $("#blockchainAddressInput").val(accounts[0]);
          $("#blockchainAddressInput").closest("form").trigger("submit");
        }
        break;

      } catch (ex) {} 

      await new Promise(r => setTimeout(r, 2000));

    } while (new Date().getTime() < start + 10000);
  });

  // 	ethereum.request({ 
  // 		method: 'wallet_revokePermissions',
  // 		params:[{
  //     	eth_accounts: {}
  // 		}]});
  //}, 10);
}

async function setupQrScan()
{
  var qrscanning = false;

  const codeReader = new BrowserQRCodeReader();
  var devices = await BrowserCodeReader.listVideoInputDevices();
  if (!devices || !devices.length)
  {
    return;
  }

  $("html").addClass("has-camera");

  $("#qrcodeModal").on("shown.bs.modal", function ()
  {
    var qrcodeModal = bootstrap.Modal.getInstance("#qrcodeModal");

    qrscanning = true;
    codeReader.decodeFromVideoDevice(null, 'qrcode-preview', (result, err, controls) =>
    {
      if (!qrscanning)
      {
        controls.stop();
        return;
      }
      if (result)
      {
        qrscanning = false;
        controls.stop();
        qrcodeModal.hide();

        var text = result.text || result;
        var match = /^([a-z]+\:)?(0x[0-9a-z]{40,})$/i.exec(text);
        if (match)
        {
          $("#blockchainAddressInput").val(match[2]);
          $("#blockchainAddressInput").closest("form").trigger("submit");
        }
        else
        {
          $("#blockchainAddressInput").val(text);
        }
        return;
      }

      if (err)
      {
        // As long as this error belongs into one of the following categories
        // the code reader is going to continue as excepted. Any other error
        // will stop the decoding loop.
        //
        // Excepted Exceptions:
        //
        //  - NotFoundException
        //  - ChecksumException
        //  - FormatException

        // if (err instanceof ZXingBrowser.NotFoundException) {
        // 	console.log('No QR code found.')
        // }

        // if (err instanceof ZXingBrowser.ChecksumException) {
        // 	console.log('A code was found, but it\'s read value was not valid.')
        // }

        // if (err instanceof ZXingBrowser.FormatException) {
        // 	console.log('A code was found, but it was in a invalid format.')
        // }
      }
    });
  });
  $("#qrcodeModal").on("hide.bs.modal", function ()
  {
    qrscanning = false;
  });
}

function setupZohoform(t)
{
  // once the form has loaded we need to fix the look of the button

  var $btn = $("#feedbPopupSbmtBtn");
  if ($btn.length == 0)
  {
    t = !t ? 100 : t * 2;
    setTimeout(() => {
      setupZohoform(t);
    }, t);
    return;
  }

  $("#feedbPopupSbmtBtn").removeClass("dt-blue-btn").addClass("submitBtn btn btn-primary").val("Send Message");
}

(async function()
{
  setupZohoform();
  setupAddressSearch();
  //await setupQrScan();
  //await setupMetamask();
 
  // legacy code doesn't fire so we need to process it
  $("button.landing-back-top").on("click", () =>
  {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });
})();
